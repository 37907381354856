import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="About VeganBlender" />

    <div className="">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About Us 
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              Why we're passionate about vegan blender recipes
            </h2>
            <div className="mt-4 leading-loose">
              Every new vegan goes through a series of epiphanies that take their recipes to a whole new level of tastiness.
               Whether, it's the first time you discover nooch, experimenting with soy curls and TVP, or ordering new starches and flours you've never heard of your vegan culinary repetoire is constantly growing.
              <br />
              <br />
              However, nothing was as life changing as the day we first got our Vitamix. Creamy sauces, nut butters, and vegan cheeses that we never thought possible are now a staple in our plant based diet. It's been so impactful that we felt the need to consolidate all the tips and recipes here to share with everyone else interested in vegan food.
              <br />
              <br />
              Want to get in touch? I'm happy to hear from you:
              <br />
              <a
                href="mailto:hello@veganblender.com"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                hello@veganblender.com
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="Vegan Blender"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 680, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
